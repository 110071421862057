@import "~antd/dist/antd.css";

@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@layer base {
	html,
	body {
		-webkit-tap-highlight-color: transparent;
	}

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
		width: 99vw;
	}

	/* Firefox */
	input[type="number"] {
		-moz-appearance: textfield;
	}

	body {
		@apply dark:text-white;
	}
}

@layer components {
	.tw-container {
		@apply w-11/12 lg: max-w-[80rem] mx-auto;
	}

	.stageStartBtn {
		clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 0% 50%, 0% 0%);
	}

	.stageBtn {
		clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%);
	}

	.stageEndBtn {
		clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 10% 50%, 0% 0%);
	}
}

html,
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.ant-btn-primary {
	background: #1890ff !important;
}

.ant-btn-primary:focus,
.ant-btn-primary:hover {
	background: #40a9ff !important;
}

.ant-table .ant-table-container .ant-table-content table thead.ant-table-thead {
	background-color: #f3f4f6 !important;
	color: black !important;
}

.ant-table-thead > tr > th {
	color: black !important;
	font-weight: 700;
	background-color: transparent;
	font-size: 13px;
}

.ant-layout-sider {
	color: #ffffff;
}

.ant-menu-item a {
	color: white !important;
	padding: 0 10px;
}

/* .ant-menu-item .active {
  background-color: #3a3a3a !important;
} */

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
	visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
	visibility: visible !important;
}

.ant-picker-panels > *:last-child {
	display: none;
}

.ant-picker-panel-container,
.ant-picker-footer {
	width: 280px !important;
}

.ant-picker-footer-extra > div {
	flex-wrap: wrap !important;
}

.ant-card-head {
	min-height: 40px;
}
